import React, { useState } from "react"
// import PropTypes from 'prop-types';
import { Typography, Grid, CardMedia, CardContent, Card } from "@mui/material"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import useWindowDimensions from "./Hooks/useWindowDimensions"
function CardSimpleHorizontal({ item }) {
  // paragraph={true} para el de parrafo poner en default no y si es parrafo se active
  // ver las otras variables si les meto default o agrego en strapi no se

  // console.log("item : " + item.texto)
  // console.log(useWindowDimensions().width);
  // const [dimensiones, setDimensiones] = useState()
  const hasWindow = typeof window !== "undefined"
  // if (hasWindow){
  //   setDimensiones(useWindowDimensions())
  // }
  // const dimensiones = useWindowDimensions()
  // const esMovil = dimensiones.width < 600
  const esMovil = hasWindow && useWindowDimensions().width < 600
  // const esMovil = false
  // const esMovil = true

  // return esMovil ? item.posicion_imagen === "izquierda" || esMovil ? (
  return esMovil ? (
    <Grid
      container
      direction="row"
      item
      sx={{ width: "100%", padding: item.padding }}
    >
      <Grid sx={{ width: "100%" }} sm={6}>
        {/* titulo */}
        <Grid
          item
          sx={{
            // width: "100%",
            padding: `${item.propiedades_titulo.padding}px`,
          }}
        >
          <br />
          <br />

          <Typography
            // component={item.tipo}
            // variant={item.tipo}
            // gutterBottom={item.gutter_bottom}
            align={item.propiedades_titulo.alineacion}
            paragraph={true}

            sx={{
              fontSize: 30,
              color: `${item.propiedades_titulo.color_letra}`,
              // backgroundColor: `${item.color_fondo}`,
              fontStyle: `${item.propiedades_titulo.font_style}`,
              fontFamily: `${
                item.propiedades_titulo.tipografia === "roboto_slab"
                  ? "Roboto Slab"
                  : item.propiedades_titulo.tipografia === "roboto_mono"
                  ? "Roboto Mono"
                  : item.propiedades_titulo.tipografia === "bebas_neue"
                  ? "Bebas Neue"
                  : item.propiedades_titulo.tipografia
              }`,
              fontWeight: `${item.propiedades_titulo.font_weight}`,
              // padding: `${item.propiedades_titulo.padding}px`,
              // textAlign: `${item.propiedades_titulo.alineacion}`
            }}
          >
            {item.titulo}
          </Typography>
        </Grid>

        {/* texto */}
        <Grid
          item
          sx={{
            width: "100%",
            padding: `${item.propiedades_texto.padding}px`,
            // textAlign: `${item.propiedades_texto.alineacion}`
          }}
        >
          <Typography
            // component={item.tipo}
            // variant={item.tipo}
            // gutterBottom={item.gutter_bottom}
            // align={item.propiedades_texto.alineacion}
            // paragraph={true}

            sx={{
              color: `${item.propiedades_texto.color_letra}`,
              // backgroundColor: `${item.color_fondo}`,
              fontStyle: `${item.propiedades_texto.font_style}`,
              fontFamily: `${
                item.propiedades_texto.tipografia === "roboto_slab"
                  ? "Roboto Slab"
                  : item.propiedades_texto.tipografia === "roboto_mono"
                  ? "Roboto Mono"
                  : item.propiedades_texto.tipografia === "bebas_neue"
                  ? "Bebas Neue"
                  : item.propiedades_texto.tipografia
              }`,
              fontWeight: `${item.propiedades_texto.font_weight}`,
              // padding: `${item.propiedades_texto.padding}px`,
              textAlign: `${item.propiedades_texto.alineacion}`
            }}
          >
            {item.texto.split("\n").map((item, key) => {
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              )
            })}
          </Typography>
        </Grid>
      </Grid>

      <Grid sx={{ width: "100%" }} sm={6}>
        <CardMedia>
          {item.imagen && (
            <GatsbyImage
              // me falta TODO null de la imagen en algun lado lo hice bien con || &&
              image={item.imagen[0].file.childImageSharp.gatsbyImageData}
              formats={["auto", "webp", "avif"]}
              // layout="fullWidth"
              alt=""
              placeholder="blurred" // pasar a strapi estas opciones y ded ahi a componente
            />
          )}
        </CardMedia>
      </Grid>
    </Grid>
  ) : item.posicion_imagen === "izquierda" ? (
    <Grid
      container
      direction="row"
      item
      sx={{ width: "100%", padding: item.padding }}
    >
      <Grid sx={{ width: "100%" }} sm={6}>
        <CardMedia>
          {item.imagen && (
            <GatsbyImage
              // me falta TODO null de la imagen en algun lado lo hice bien con || &&
              image={item.imagen[0].file.childImageSharp.gatsbyImageData}
              formats={["auto", "webp", "avif"]}
              // layout="fullWidth"
              alt=""
              placeholder="blurred" // pasar a strapi estas opciones y ded ahi a componente
            />
          )}
        </CardMedia>
      </Grid>
      <Grid sx={{ width: "100%" }} sm={6}>
        {/* titulo */}
        <Grid
          item
          sx={{
            // width: "100%",
            padding: `${item.propiedades_titulo.padding}px`,
          }}
        >
          <br />
          <br />

          <Typography
            // component={item.tipo}
            // variant={item.tipo}
            // gutterBottom={item.gutter_bottom}
            align={item.propiedades_titulo.alineacion}
            // paragraph={true}

            sx={{
              fontSize: 30,
              color: `${item.propiedades_titulo.color_letra}`,
              // backgroundColor: `${item.color_fondo}`,
              fontStyle: `${item.propiedades_titulo.font_style}`,
              fontFamily: `${
                item.propiedades_titulo.tipografia === "roboto_slab"
                  ? "Roboto Slab"
                  : item.propiedades_titulo.tipografia === "roboto_mono"
                  ? "Roboto Mono"
                  : item.propiedades_titulo.tipografia === "bebas_neue"
                  ? "Bebas Neue"
                  : item.propiedades_titulo.tipografia
              }`,
              fontWeight: `${item.propiedades_titulo.font_weight}`,
              // padding: `${item.propiedades_titulo.padding}px`,
            }}
          >
            {item.titulo}
          </Typography>
        </Grid>

        {/* texto */}
        <Grid
          item
          sx={{
            width: "100%",
            padding: `${item.propiedades_texto.padding}px`,
          }}
        >
          <Typography
            // component={item.tipo}
            // variant={item.tipo}
            // gutterBottom={item.gutter_bottom}
            align={item.propiedades_texto.alineacion}
            // paragraph={true}

            sx={{
              color: `${item.propiedades_texto.color_letra}`,
              // backgroundColor: `${item.color_fondo}`,
              fontStyle: `${item.propiedades_texto.font_style}`,
              fontFamily: `${
                item.propiedades_texto.tipografia === "roboto_slab"
                  ? "Roboto Slab"
                  : item.propiedades_texto.tipografia === "roboto_mono"
                  ? "Roboto Mono"
                  : item.propiedades_texto.tipografia === "bebas_neue"
                  ? "Bebas Neue"
                  : item.propiedades_texto.tipografia
              }`,
              fontWeight: `${item.propiedades_texto.font_weight}`,
              // padding: `${item.propiedades_texto.padding}px`,
            }}
          >
            {item.texto.split("\n").map((item, key) => {
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              )
            })}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      direction="row"
      item
      sx={{ width: "100%", padding: item.padding }}
    >
      <Grid xs={6}>
        {/* titulo */}
        <Grid
          item
          sx={{
            // width: "100%",
            padding: `${item.propiedades_titulo.padding}px`,
          }}
        >
          <br />
          <br />
          <Typography
            // component={item.tipo}
            // variant={item.tipo}
            // gutterBottom={item.gutter_bottom}
            align={item.propiedades_titulo.alineacion}
            // paragraph={true}

            sx={{
              fontSize: 30,
              color: `${item.propiedades_titulo.color_letra}`,
              // backgroundColor: `${item.color_fondo}`,
              fontStyle: `${item.propiedades_titulo.font_style}`,
              fontFamily: `${
                item.propiedades_titulo.tipografia === "roboto_slab"
                  ? "Roboto Slab"
                  : item.propiedades_titulo.tipografia === "roboto_mono"
                  ? "Roboto Mono"
                  : item.propiedades_titulo.tipografia === "bebas_neue"
                  ? "Bebas Neue"
                  : item.propiedades_titulo.tipografia
              }`,
              fontWeight: `${item.propiedades_titulo.font_weight}`,
              // padding: `${item.propiedades_titulo.padding}px`,
            }}
          >
            {item.titulo}
          </Typography>
        </Grid>

        {/* texto */}
        <Grid
          item
          sx={{
            width: "100%",
            padding: `${item.propiedades_texto.padding}px`,
          }}
        >
          <Typography
            // component={item.tipo}
            // variant={item.tipo}
            // gutterBottom={item.gutter_bottom}
            align={item.propiedades_texto.alineacion}
            // paragraph={true}

            sx={{
              color: `${item.propiedades_texto.color_letra}`,
              // backgroundColor: `${item.color_fondo}`,
              fontStyle: `${item.propiedades_texto.font_style}`,
              fontFamily: `${
                item.propiedades_texto.tipografia === "roboto_slab"
                  ? "Roboto Slab"
                  : item.propiedades_texto.tipografia === "roboto_mono"
                  ? "Roboto Mono"
                  : item.propiedades_texto.tipografia === "bebas_neue"
                  ? "Bebas Neue"
                  : item.propiedades_texto.tipografia
              }`,
              fontWeight: `${item.propiedades_texto.font_weight}`,
              // padding: `${item.propiedades_texto.padding}px`,
            }}
          >
            {item.texto.split("\n").map((item, key) => {
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              )
            })}
          </Typography>
        </Grid>
      </Grid>
      <Grid xs={6}>
        <CardMedia>
          {item.imagen && (
            <GatsbyImage
              // me falta TODO null de la imagen en algun lado lo hice bien con || &&
              image={item.imagen[0].file.childImageSharp.gatsbyImageData}
              formats={["auto", "webp", "avif"]}
              // layout="fullWidth"
              alt=""
              placeholder="blurred" // pasar a strapi estas opciones y ded ahi a componente
            />
          )}
        </CardMedia>
      </Grid>
    </Grid>
  )
}

export default CardSimpleHorizontal
