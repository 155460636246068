import { Grid } from "@mui/material"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

function PasadorImagenesLinkInterno({ item }) {
  let i
  const [ind, setInd] = useState(0)
  const cambiarImagen = () => {
    if (i < item.imagenes.length - 1) {
      i = i + 1
      setInd(ind => ind + 1)
    } else {
      setInd(0)
      i = 0
    }
  }
  useEffect(() => {
    if (
      item.imagenes !== null &&
      item.imagenes.length > 1 &&
      item.temporizador !== 0
    ) {
      const interval = setInterval(() => {
        cambiarImagen()
      }, item.temporizador)
      return () => clearInterval(interval)
    }
  }, [])
  const irA = item => {
    if (item.links[ind] !== null) {
      // window.open(item.links[ind].link, "_blank")
      navigate(item.links[ind].link)
      // navigate(item.links[ind].link)
    }
    //  else {
    //   window.open(item.link, "_blank")
    // }
  }

  return (
    <Grid item sx={{ width: "100%", padding: item.padding }}
    onClick={() => irA(item)}
    
    >
      {item.imagenes && (
        <GatsbyImage
          image={item.imagenes[ind].file.childImageSharp.gatsbyImageData}
          formats={["auto", "webp", "avif"]}
          alt={item.imagenes[ind].alternativeText}
          placeholder="blurred" // pasar a strapi estas opciones y ded ahi a componente
          // me falta TODO null de la imagen en algun lado lo hice bien con || &&
          // layout="fullWidth"
          // # TODO revisar porque con mas width del que tiene la imagen no se ve
          // layout="constrained"
          //  objectFit="scale-down"
          //  imgStyle={{padding:40}}
          //  imgStyle={{padding:40, maxWidth: "50%", maxHeight: "50%"}}
          style={{
            cursor: "pointer",
            //   padding: `${item.propiedades_texto.padding}px`,
          }}
        />
      )}
    </Grid>
  )
}

export default PasadorImagenesLinkInterno
