import React from "react"
import { Button, Tooltip, Grid } from "@mui/material"
import { navigate } from "gatsby"
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image"

function BotonImagen({ item }) {
  const irA = item => {
    if (item.destinoDelLink === "interno") {
      navigate(item.link)
    } else {
      window.open(item.link, "_blank")
    }
  }

  return (
    <Grid item sx={{ width: "100%" ,
    // padding: `${item.propiedades_texto.padding}px`,
    }}>
      {/* <Tooltip title={tooltipItem} arrow placement="top"> TODO placement del tooltip*/}
      <div
        fullWidth
        variant={"outlined"}
        style={{
        //   backgroundColor: `${item.color_fondo}`,
        //   fontWeight: `${item.propiedades_texto.font_weight}`,
        //   padding: `${item.propiedades_texto.padding}px`,
        //   "&:hover": { backgroundColor: `${item.color_fondo}E6` }, //TODO estoy aca
          // https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
          // padding: `${item.propiedades_texto.padding}px`,

        }}
        onClick={() => irA(item)}
        // size={item.size}
      >
        <GatsbyImage
          image={item.imagen[0].file.childImageSharp.gatsbyImageData}
          style={{
          cursor: "pointer",
        //   padding: `${item.propiedades_texto.padding}px`,


          }}
          formats={["auto", "webp", "avif"]}
          alt={item.imagen[0].alternativeText}
          placeholder="blurred" // pasar a strapi estas opciones y ded ahi a componente
        />
      </div>
      {/* </Tooltip> */}
    </Grid>
  )
}

export default BotonImagen
