import React from "react"
// import PropTypes from 'prop-types';
import { Typography, Grid, CardMedia, CardContent, Card } from "@mui/material"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

function CardSimple({ item }) {
  // paragraph={true} para el de parrafo poner en default no y si es parrafo se active
  // ver las otras variables si les meto default o agrego en strapi no se

  // console.log("item : " + item.texto)
  return (
    <Grid container item sx={{ width: "100%", padding: item.padding }}>
      {/* <Card sx={{ maxWidth: 345 }}> */}
      <Card>
        {/* <CardActionArea> */}
        {/* <CardMedia
          component="img"
          height="140"
          image="/static/images/cards/contemplative-reptile.jpg"
          alt="green iguana"
        /> */}
        <CardMedia>
          {item.imagen && 
          <GatsbyImage
            // me falta TODO null de la imagen en algun lado lo hice bien con || &&
            image={item.imagen[0].file.childImageSharp.gatsbyImageData}
            formats={["auto", "webp", "avif"]}
            // layout="fullWidth"
            alt=""
            placeholder="blurred" // pasar a strapi estas opciones y ded ahi a componente
          />}
        </CardMedia>
        <CardContent>
         {/* titulo */}
         <Grid
                        item
                        sx={{
                          width: "100%",
                          padding: `${item.propiedades_titulo.padding}px`,
                        }}
                      >
                        <Typography
                          // component={item.tipo}
                          // variant={item.tipo}
                          // gutterBottom={item.gutter_bottom}
                          align={item.propiedades_titulo.alineacion}
                          // paragraph={true}

                          sx={{
                            color: `${item.propiedades_titulo.color_letra}`,
                            // backgroundColor: `${item.color_fondo}`,
                            fontStyle: `${item.propiedades_titulo.font_style}`,
                            fontFamily: `${
                              item.propiedades_titulo.tipografia ===
                              "roboto_slab"
                                ? "Roboto Slab"
                                : item.propiedades_titulo.tipografia ===
                                  "roboto_mono"
                                ? "Roboto Mono"
                                : item.propiedades_titulo.tipografia ===
                                  "bebas_neue"
                                ? "Bebas Neue"
                                : item.propiedades_titulo.tipografia
                            }`,
                            fontWeight: `${item.propiedades_titulo.font_weight}`,
                            // padding: `${item.propiedades_titulo.padding}px`,
                          }}
                        >
                          {item.titulo}
                        </Typography>
                      </Grid>

                      {/* texto */}
                      <Grid
                        item
                        sx={{
                          width: "100%",
                          padding: `${item.propiedades_texto.padding}px`,
                        }}
                      >
                        <Typography
                          // component={item.tipo}
                          // variant={item.tipo}
                          // gutterBottom={item.gutter_bottom}
                          align={item.propiedades_texto.alineacion}
                          // paragraph={true}

                          sx={{
                            color: `${item.propiedades_texto.color_letra}`,
                            // backgroundColor: `${item.color_fondo}`,
                            fontStyle: `${item.propiedades_texto.font_style}`,
                            fontFamily: `${
                              item.propiedades_texto.tipografia ===
                              "roboto_slab"
                                ? "Roboto Slab"
                                : item.propiedades_texto.tipografia ===
                                  "roboto_mono"
                                ? "Roboto Mono"
                                : item.propiedades_texto.tipografia ===
                                  "bebas_neue"
                                ? "Bebas Neue"
                                : item.propiedades_texto.tipografia
                            }`,
                            fontWeight: `${item.propiedades_texto.font_weight}`,
                            // padding: `${item.propiedades_texto.padding}px`,
                          }}
                        >
                          {item.texto.split("\n").map((item, key) => {
                            return (
                              <span key={key}>
                                {item}
                                <br />
                              </span>
                            )
                          })}
                        </Typography>
                      </Grid>

        </CardContent>
        {/* </CardActionArea> */}
      </Card>
    </Grid>
  )
}

export default CardSimple
